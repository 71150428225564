import React from 'react'
import { Link } from 'react-router-dom';
import Sorter from '../../../shared/components/sorting/sorter';
import { CompanyPage } from '../../../store/companies/interface';

const CompaniesList = ({
    pageInfo,
    sortBy,
    sortType,
    setSortBy,
    setSortType
}: {
    pageInfo: CompanyPage;
    sortBy: string;
    sortType: string;
    setSortBy: any;
    setSortType: any;
} ) => {
    return (
        <>
            <div className="jax-table-outer ml-1rem mr-1rem">
                <div className="table-responsive jax-table">
                    <table className="table mb-0 table-striped">
                        <thead>
                            <tr>
                                <th style={{ width: "20%" }}  onClick={()=>{ setSortBy('name'); setSortType( sortType === 'ASC' ? 'DESC' : 'ASC'); }} className='pointer'>
                                    COMPANY NAME
                                    <Sorter setSortType={setSortType} sortType={sortType} isActive={sortBy === 'name'} />
                                </th>
                                <th style={{ width: "20%" }}  onClick={()=>{ setSortBy('number'); setSortType( sortType === 'ASC' ? 'DESC' : 'ASC'); }} className='pointer'>
                                    COMPANY NUMBER
                                    <Sorter setSortType={setSortType} sortType={sortType} isActive={sortBy === 'number'} />
                                </th>
                                <th style={{ width: "20%" }}  onClick={()=>{ setSortBy('sub_domain'); setSortType( sortType === 'ASC' ? 'DESC' : 'ASC'); }} className='pointer'>
                                    SUB DOMAIN
                                    <Sorter setSortType={setSortType} sortType={sortType} isActive={sortBy === 'sub_domain'} />
                                </th>
                                <th style={{ width: "20%" }} onClick={()=>{ setSortBy('phone_number'); setSortType( sortType === 'ASC' ? 'DESC' : 'ASC'); }} className='pointer'>
                                    PHONE NUMBER
                                    <Sorter setSortType={setSortType} sortType={sortType} isActive={sortBy === 'phone_number'} />
                                </th>
                               
                                <th style={{ width: "20%" }}  onClick={()=>{ setSortBy('email'); setSortType( sortType === 'ASC' ? 'DESC' : 'ASC'); }} className='pointer'>
                                    EMAIL
                                    <Sorter setSortType={setSortType} sortType={sortType} isActive={sortBy === 'email'} />
                                </th>
                                <th style={{ width: "20%" }} className='pointer'>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {pageInfo &&
                                pageInfo?.companiesList &&
                                pageInfo?.companiesList.map((company) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>
                                                    <Link to={`/company-details/${company.id}`} className="company-link">
                                                        {company.name}
                                                    </Link>
                                                </td>
                                                <td>{company.number}</td>
                                                <td>{company.sub_domain}</td>
                                                <td>
                                                    {company.phone_number}
                                                </td>                                                
                                                <td>{company.email}</td>
                                                <td>
                                                    <Link
                                                        type="button"
                                                        className="btn btn-outline-secondary btn-xs"
                                                        title="Edit"
                                                        to={`/company/${company.id}`}
                                                    >
                                                        <i
                                                        className="fa fa-pencil"
                                                        aria-hidden="true"
                                                        ></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default CompaniesList