import React from 'react'
import Layout from '../../shared/components/layout/layout'
import { useParams } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import Api from "../../Api";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from '../../store/loader/model';
import { Company } from '../../store/companies/interface';
import { formatPhoneNumber } from '../../utils/index';
import JAXModal from '../../shared/components/modal/jax-modal';
import AddCompanyDocuments from './document';
import { deleteCompanyDoc } from '../../store/companies/action';
import NotesSectionComponent from '../../shared/components/notes/notes-section-component';
import CompanyRental from './rentals';
import CompanyFinance from './companyFinanace';

const CompanyDetails = () => {
    const { id } = useParams();
    const [company, setCompany] = useState<Company | null>(null)
    const [companyDocs, setCompanyDocs] = useState<any>(null)
    const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false)
    const dispatch = useDispatch()
    useLayoutEffect(() => {
        const fetchCompany = async () => {
            dispatch(new Loader(true).action());
            try {
                const res = await Api.get(`/api/v1/companies/${id}`)
                const docsRes = await Api.get(`/api/v1/company-documents/search/${id}`)
                dispatch(new Loader(false).action());
                if(res?.data?.data) {
                    setCompany(res?.data?.data)
                }
                if(docsRes?.data?.data) {
                    setCompanyDocs(docsRes?.data?.data)
                }
            } catch(e: any) {
                dispatch(new Loader(false).action());
                throw e.message
            }
        };  
        if(id) {
            fetchCompany()
        }
    }, [id])
    console.log(company)
    return (
        <>
            <Layout>
            <section className="content-body">
                <header className="page-header">
                <div className="page_title">
                <h2>Company Details</h2>
                </div>
                {/* <RentalHeaderActions rental={pageInfo?.rental}></RentalHeaderActions> */}
            </header>

            <div className="page_content">
                <div className="white-box">
                <div className="row">
                    <div className="col-lg-12 col-xl-8">
                        <section className="card mb20">
                            <header className="card-header">
                                <h2 className="card-title">
                                Company Information
                                </h2>
                            </header>
                            <div className="card-body">
                                <div className='row'>
                                    <h2>{company?.name}</h2>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="info-list">
                                        <li>
                                            <div className="info-list-item">
                                                <label className="info-label">Company Number</label>
                                                <div className="info-name">{ company?.number }</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-list-item">
                                                <label className="info-label">Website</label>
                                                <div className="info-name">{ company?.website }</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-list-item">
                                            <label className="info-label">EIN</label>
                                            <div className="info-name">
                                            {company?.ein}
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-list-item">
                                            <label className="info-label">Sub Domain</label>
                                            <div className="info-name">
                                            {company?.sub_domain}
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-list-item">
                                            <label className="info-label">Company Phone number</label>
                                            <div className="info-name">
                                            {company?.phone_number && formatPhoneNumber(company?.phone_number)}
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-list-item">
                                            <label className="info-label">Company Email</label>
                                            <div className="info-name">
                                                {company?.email}
                                            </div>
                                            </div>
                                        </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="info-list">
                                        <li>
                                            <div className="info-list-item">
                                            <label className="info-label">Addres Line 1</label>
                                            <div className="info-name" style={ { textTransform: "capitalize" } }>
                                                {company?.address_1}
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-list-item">
                                            <label className="info-label">Address Line 2</label>
                                            <div className="info-name" style={ { textTransform: "capitalize" } }>
                                                {company?.address_2}
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-list-item">
                                            <label className="info-label">City</label>
                                            <div className="info-name">
                                                {company?.city}
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-list-item">
                                            <label className="info-label">State</label>
                                            <div className="info-name">
                                                {company?.state}
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-list-item">
                                            <label className="info-label">Zipcode</label>
                                            <div className="info-name">{company?.zip_code}</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-list-item">
                                            <label className="info-label">Commission</label>
                                            <div className="info-name">
                                                {company?.commission}
                                            </div>
                                            </div>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='row'>
                                    {
                                        company?.companyContacts && company?.companyContacts.length && 
                                        company?.companyContacts.map((contact) => (
                                            <>
                                            <div className="col-md-6">
                                                <ul className="info-list">
                                                    <li>
                                                        <div className="info-list-item">
                                                            <label className="info-label">Contact Name {contact.isPrimary ? "(Admin)" : ""}</label>
                                                            <div className="info-name">
                                                                {contact.firstName} {contact.lastName}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='col-md-6'>
                                                <ul className="info-list">
                                                    <li>
                                                        <div className="info-list-item">
                                                        <label className="info-label">Contact Email {contact.isPrimary ? "(Admin)" : ""}</label>
                                                        <div className="info-name">
                                                            {contact.email}
                                                        </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='col-md-6'>
                                                <ul className="info-list">
                                                    <li>
                                                        <div className="info-list-item">
                                                            <label className="info-label">Contact Phone No. {contact.isPrimary ? "(Admin)" : ""}</label>
                                                            <div className="info-name">
                                                                {contact.phone_number && formatPhoneNumber(contact.phone_number)}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='col-md-6'>
                                                <ul className="info-list">
                                                    <li>
                                                        <div className="info-list-item">
                                                        <label className="info-label">Contact Fax {contact.isPrimary ? "(Admin)" : ""}</label>
                                                        <div className="info-name">
                                                            {contact.extension}-{contact.fax}
                                                        </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </section >
                        <NotesSectionComponent companyId = {Number(id)}></NotesSectionComponent>
                        <CompanyRental companyId = {Number(id)}/>
                        {/* <RentalInformation rental={ pageInfo?.rental } setShowEditRental={() => setShowEditRental(true)} ></RentalInformation>
                        <CustomerInformation user={pageInfo?.rental?.userDetails} ></CustomerInformation>
                        <NotesSectionComponent rentalId = {Number(rentalID)}></NotesSectionComponent>
                        <RentalStats rental={ pageInfo?.rental } transactions={ pageInfo?.paymentList}></RentalStats>
                        </div>
                        <div className="col-lg-12 col-xl-4">
                        <RentalTimer rental={pageInfo?.rental}></RentalTimer>
                        <RentalDocuments rentals={pageInfo.rental}></RentalDocuments>
                        <VehicleInformation rental={pageInfo?.rental} vehicle = {pageInfo?.rental?.vehicleDetails}></VehicleInformation>
                        <PaymentDetails rental={pageInfo?.rental}></PaymentDetails>
                        <VehicleSwitchHistory rental={pageInfo?.rental}></VehicleSwitchHistory>
                        <Inspections rental={pageInfo?.rental} ></Inspections> */}
                    </div>
                    <div className="col-lg-12 col-xl-4">
                        <section className="card mb20 remaining-time">
                            <header className="card-header">
                                <h2 className="card-title">Documents</h2>
                                <div className="card-head-actions">
                                    {/* eslint-disable-next-line  */}
                                    <a className="btn orange-circle radius-sm" href="#" onClick={() => setShowDocumentModal(true)}>
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </header>
                            <div className="card-body">
                                <ul className="list-style-none doc-list">
                                    {
                                        companyDocs && companyDocs.map((document: any) => <li>
                                            <div className="doc-name">
                                                <i
                                                className="fa fa-address-card-o mr-2"
                                                aria-hidden="true"
                                                ></i>
                                                {document?.name}
                                            </div>
                                            <div className="doc-action">
                                                <a
                                                href={document.url}
                                                className="btn btn-outline-success btn-xs mr-1"
                                                title="Download Document"
                                                target={"_blank"}
                                                rel="noreferrer"
                                                >
                                                <i
                                                    className="fa fa-download"
                                                    aria-hidden="true"
                                                ></i>
                                                </a>
                                                <a
                                                    href="javascript:void(0);"
                                                    className="btn btn-outline-danger btn-xs mr-1"
                                                    onClick={
                                                        () => {
                                                            if(company && company.id) {
                                                                dispatch(deleteCompanyDoc({
                                                                    id: document.id, companyId: company.id
                                                                }))
                                                            }
                                                        }
                                                    }
                                                >
                                                <i
                                                    className="fa fa-times"
                                                    aria-hidden="true"
                                                ></i>
                                                </a>
                                                
                                            </div>
                                        </li>)
                                    }
                                </ul>
                            </div>
                            <JAXModal
                                heading={`Add Documents`} 
                                show={ showDocumentModal } 
                                dialogClassName="single-upload-modal"
                                handleClose={() => setShowDocumentModal(false)}
                            >
                                <AddCompanyDocuments company={company} handleClose={() => setShowDocumentModal(false)} />
                            </JAXModal>
                        </section>
                        <CompanyFinance companyId={Number(id)} />
                    </div>
                </div>
                </div>
            </div>
            </section>
            </Layout>
        </>
    )
}

export default CompanyDetails