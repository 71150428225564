import React from 'react'
import { Link } from 'react-router-dom';
import { FilterInterface } from './index';

const CompaniesFilter = ({
    expandFilters,
    setExpandFilters,
    filters,
    handleFilters,
    search,
    reset,
}: {
    expandFilters: boolean;
    setExpandFilters: (status: boolean) => void;
    filters: FilterInterface,
    handleFilters: (e: any) => void;
    search: () => void;
    reset: () => void;
}) => {
    return (
        <>
        <section className="card mb20">
        <header className="card-header card-head-icon">
          <div className="card-head-actions-left">
            <Link className="btn btn-orange btn-sm" to="/company/add">
              <i className="fa fa-plus mr-1" aria-hidden="true"></i>
              Add New Company
            </Link>
          </div>
          <div className="card-head-actions card-head-arrow">
            {expandFilters ? (
              <span
                className="btn arrow-circle-up"
                onClick={() => setExpandFilters(false)}
              >
                <i className="fa fa-chevron-up" aria-hidden="true"></i>
              </span>
            ) : (
              <span
                className="btn arrow-circle-up"
                onClick={() => setExpandFilters(true)}
              >
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
              </span>
            )}
          </div>
        </header>
        <div
          className="card-body"
          style={{ display: expandFilters ? "block" : "none" }}
        >
          <div className="row row-column-5">
            <div className="col-lg-3 col-md-4">
              <div className="form-group">
                <label>Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  name="name"
                  autoComplete="off"
                  value={
                    filters.name ? filters.name : ""
                  }
                  onChange={(e) => handleFilters(e)}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="form-group">
                <label>Company Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Number"
                  name="number"
                  autoComplete="off"
                  value={
                    filters.number ? filters.number : ""
                  }
                  onChange={(e) => handleFilters(e)}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="form-group">
                <label>Sub Domain</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Sub Domain"
                  name="sub_domain"
                  autoComplete="off"
                  value={
                    filters.sub_domain ? filters.sub_domain : ""
                  }
                  onChange={(e) => handleFilters(e)}
                />
              </div>
            </div> 
            <div className="col-lg-3 col-md-4">
              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  name="phone_number"
                  autoComplete="off"
                  value={
                    filters.phone_number ? filters.phone_number : ""
                  }
                  onChange={(e) => handleFilters(e)}
                />
              </div>
            </div>  
            <div className="col-lg-3 col-md-4">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  autoComplete="off"
                  value={
                    filters.email ? filters.email : ""
                  }
                  onChange={(e) => handleFilters(e)}
                />
              </div>
            </div>    
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-action text-right mt-2 mb-2">
                <button
                  className="btn btn-orange mr-1"
                  type="submit"
                  onClick={search}
                >
                  Filter
                </button>
                <button
                  className="btn btn-orange"
                  type="submit"
                  onClick={reset}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    )
}

export default CompaniesFilter