import React from 'react'
import Layout from '../../../shared/components/layout/layout'
import { useState } from 'react';
import {AiOutlinePlusCircle, AiOutlineDelete} from 'react-icons/ai'
import CustomDropdown from '../../../shared/components/custom-dropdown/custom-dropdown';
import { DROP_DOWN_TYPES } from '../../../shared/models';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchDropdownValues } from '../../../store/dropdownValues/action';
import { DropdownValuesState } from '../../../store/vehicleTypes/model';
import { StoreInterface } from '../../../store';
import { useSelector } from 'react-redux';
import { ResponseError, ResponseSuccess } from '../../../store/shared/model';
import { useNavigate, useParams } from 'react-router-dom';
import Api from '../../../Api'
import { Company, CompanyPage } from '../../../store/companies/interface';
import { useEffect } from 'react';
import InputMask from "react-input-mask";

export interface FormInterface {
    name: string|null,
    number: string|null,
    sub_domain: string|null,
    website: string|null,
    ein: string|null,
    phone_number: string|null,
    email: string|null,
    address_1: string|null,
    address_2: string|null,
    city: string|null,
    state: string|null,
    commission: number|null,
    zip_code: string|null,
    companyContacts: {
        id?: number | null,
        firstName: string|null,
        lastName: string|null,
        phone_number: string|null,
        email: string|null,
        extension: string|null,
        fax: string|null
    }[]
}
const EditCompany = () => {
    const initialForm = {
        name: null,
        number: null,
        sub_domain: null,
        website: null,
        ein: null,
        phone_number: null,
        email: null,
        address_1: null,
        address_2: null,
        city: null,
        state: null,
        commission: 0,
        zip_code: null,
        companyContacts: [
            {
                firstName: null,
                lastName: null,
                phone_number: null,
                email: null,
                extension: null,
                fax: null
            }
        ]
    }
    const [form, setForm] = useState<FormInterface | any>(initialForm)
    const [errors, setErrors] = useState<any>([])
    const [contactErrors, setContactErrors] = useState<any>([])
    const [company, setCompany] = useState<Company | null | any>(null)
    const { id } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const dropdownValues: DropdownValuesState = useSelector(
        ( state: StoreInterface ) => state.dropdownValues
    );
    const pageInfo = useSelector((state: StoreInterface) => state.companiesPage);
    useLayoutEffect(() => {
        dispatch( fetchDropdownValues( [
            DROP_DOWN_TYPES.STATE.toString(),
        ] ) );
        // eslint-disable-next-line
        if(pageInfo?.companiesList && id) {
            const companyFiltered = pageInfo?.companiesList.find((company: Company) => company.id === parseInt(id))
            if(companyFiltered) setCompany(companyFiltered)
        }
    }, [])

    useEffect(() => {
        console.log(company)
        if(company) {
            Object.keys(form).forEach((key: any) => {
                form[key] = company[key]
                if(key === 'companyContacts' && company[key] && company[key].length) {
                    setContacts(company[key])
                }
            })
            // console.log(form, "test")
        }
    }, [company])
    const [contacts, setContacts] = useState([
        {
            name: null,
            phone_number: null,
            email: null,
            extension: null,
            fax: null
        }
    ])
    const validate = () => {
        let isValid = true
        let errors = []
        if(!form.name) {
            errors.push({name: "Please provide company name"})
            isValid = false
        }
        if(!form.sub_domain) {
            errors.push({sub_domain: "Please provide company sub domain"})
            isValid = false
        }
        if(!form.ein) {
            errors.push({ein: "Please provide company EIN"})
            isValid = false
        }
        if(!form.email) {
            errors.push({email: "Please provide company Email"})
            isValid = false
        }
        if(!form.city) {
            errors.push({city: "Please provide company city"})
            isValid = false
        }
        if(!form.state) {
            errors.push({state: "Please provide company state"})
            isValid = false
        }
        if(!form.zip_code) {
            errors.push({zip_code: "Please provide company zip_code"})
            isValid = false
        }
        if(!form.address_1) {
            errors.push({address_1: "Please provide company address"})
            isValid = false
        }
        if(!form.website) {
            errors.push({website: "Please provide company website"})
            isValid = false
        }
        if(form.sub_domain && !(/^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{3,6}$/.test(form.sub_domain))) {
            errors.push({sub_domain: "Please provide valid company subdomain"})
            isValid = false
        }
        if(!form.phone_number) {
            errors.push({number: "Please provide company phone number"})
            isValid = false
        }
        if(!isValid) {
            setErrors(errors)
        }
        return isValid
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        const isValid = validate()
        if(isValid) {
            try {
                const updatedContacts = contacts.map((data: any) => {
                    return {
                        ...data,
                        phone_number: data.phone_number
                            ? data.phone_number
                                .replace("-", "")
                                .replace(" ", "")
                                .replace("(", "")
                                .replace(")", "")
                            : data.phone_number
                    }
                })
                form.company_contacts = updatedContacts
                delete form.companyContacts
                if(form.sub_domain) {
                    form.sub_domain = form.sub_domain?.replace("https://", "")
                    form.sub_domain = form.sub_domain?.replace("http://", "")
                    form.sub_domain = form.sub_domain.replace(/\/$/, "");
                }
                form.phone_number = form.phone_number
                ? form.phone_number
                    .replace("-", "")
                    .replace(" ", "")
                    .replace("(", "")
                    .replace(")", "")
                : form.phone_number;
                const data: any = (await Api.put( `/api/v1/companies/${company.id}`, form )).data;
                console.log(data.emailExists)
                if(data.emailExists && data.emailExists.length){
                    dispatch( new ResponseError( `Some contact's emails already exists. ${data.emailExists.join('\n')}` ).action() );
                } else {
                    dispatch( new ResponseSuccess( "Company updated successfully." ).action() );
                    navigate('/company')
                }
            }catch(e: any){
                if(e?.response?.data?.message) {
                    dispatch( new ResponseError( e?.response?.data?.message ).action() )
                }
            }
        }
    }
    const handleForm = (e: any) => {
        const name = e.target.name
        let value = e.target.value
        if(name === "commission") value = isNaN(parseFloat(value)) ? 0 : parseFloat(value)
        setForm((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    }
    const handleDropdownForm = ( name: string, value: string ) =>
    {
        setForm( {
        ...form,
        [ name ]: value,
        } );
    };
    const resetDropdownError = ( name: string ) =>
    {
        let oldErrors = errors;
        const updatedErrors = oldErrors.filter( ( error: any, index: number ) => !(name in error))
        setErrors( updatedErrors );
    };
    const resetError = (e: any) => {
        const name = e.target.name;
        let oldErrors = errors;
        const updatedErrors = oldErrors.filter( ( error: any, index: number ) => !(name in error))
        setErrors( updatedErrors );
    }
    const handleAddContact = () => {
        setContacts((prevState) => {
            return [...prevState, {
                name: null,
                phone_number: null,
                email: null,
                extension: null,
                fax: null
            }]
        })
    }
    const handleDelete = (index: number) => {
        setContacts((prevState) => {
            const filtered = prevState.filter((contact, i) => i !== index)
            return filtered
        })
    }

    const handleContactForm = (e: any, index: number) => {
        const name = e.target.name
        const value = e.target.value
        setContacts((prevState) => {
            const updatedData = prevState.map((data, i) => index === i ? {...data, [name]: value}: {...data})  
            return updatedData
        })
    }
    return (
        <Layout>
          <section className="content-body">
            <header className="page-header">
              <div className="page_title">
                <h2>Edit Company</h2>
              </div>
            </header>

            <div className="page_content">
              <div className="white-box">
                <section className="card mb20 border-none">
                  <div className="card-body p-0">
                    <div className="table-head table-single-heading">
                      <h3 className="mb-0">Update Company</h3>
                      <div className="head-page-action">
                        {/* <div className="export-btn">
                          <a
                            href="#"
                            className="btn orange-circle radius-sm"
                            data-toggle="modal"
                            data-target="#addVehiclesModal"
                          >
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                          </a>
                        </div> */}
                      </div>
                    </div>
                    <div className="tabs-column">
                        <div className="tab-content">
                            <div
                            className={
                                "tab-pane fade show active"
                            }
                            id="vehicledetail"
                            role="tabpanel"
                            aria-labelledby="vehicle-detail-tab"
                            >
                            <form onSubmit={ (e) => handleSubmit(e) }>
                                <div className="vehicle-detail-form">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>Company Name</label>
                                                <input
                                                type="text"
                                                name="name"
                                                value={form.name}
                                                className="form-control placeholder-dark"
                                                placeholder="Company Name"
                                                onChange={ ( e ) =>
                                                {
                                                    resetError( e );
                                                    handleForm( e );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.name;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>Sub Domain</label>
                                                <input
                                                type="text"
                                                value={form.sub_domain}
                                                name="sub_domain"
                                                className="form-control placeholder-dark"
                                                placeholder="Sub Domain"
                                                onChange={ ( e ) =>
                                                {
                                                    resetError( e );
                                                    handleForm( e );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.sub_domain;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>Website</label>
                                                <input
                                                type="text"
                                                name="website"
                                                value={form.website}
                                                className="form-control placeholder-dark"
                                                placeholder="Website"
                                                onChange={ ( e ) =>
                                                {
                                                    resetError( e );
                                                    handleForm( e );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.website;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>EIN</label>
                                                <input
                                                type="text"
                                                name="ein"
                                                value={form.ein}
                                                className="form-control placeholder-dark"
                                                placeholder="EIN"
                                                onChange={ ( e ) =>
                                                {
                                                    resetError( e );
                                                    handleForm( e );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.ein;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                            <label>Company Phone Number</label>
                                            <InputMask
                                                mask="(999) 999-9999"
                                                name={"phone_number"}
                                                value={form.phone_number}
                                                onChange={ ( e: any ) =>
                                                    {
                                                        resetError( e );
                                                        handleForm( e );
                                                    } }
                                                className="form-control"
                                                placeholder="45891245689"
                                            />
                                            <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.number;
                                                    } )
                                                    : "" }
                                            </span>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>Company Phone Number</label>
                                                <input
                                                type="text"
                                                name="phone_number"
                                                value={form.phone_number}
                                                className="form-control placeholder-dark"
                                                placeholder="Company Phone Number"
                                                onChange={ ( e ) =>
                                                {
                                                    resetError( e );
                                                    handleForm( e );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.phone_number;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                        </div> */}
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>Company Email</label>
                                                <input
                                                type="text"
                                                name="email"
                                                value={form.email}
                                                className="form-control placeholder-dark"
                                                placeholder="Company Email"
                                                onChange={ ( e ) =>
                                                {
                                                    resetError( e );
                                                    handleForm( e );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.email;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>Address Line 1</label>
                                                <input
                                                type="text"
                                                name="address_1"
                                                value={form.address_1}
                                                className="form-control placeholder-dark"
                                                placeholder="Address Line 1"
                                                onChange={ ( e ) =>
                                                {
                                                    resetError( e );
                                                    handleForm( e );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.address_1;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>Address Line 2</label>
                                                <input
                                                type="text"
                                                name="address_2"
                                                value={form.address_2}
                                                className="form-control placeholder-dark"
                                                placeholder="Address Line 2"
                                                onChange={ ( e ) =>
                                                {
                                                    resetError( e );
                                                    handleForm( e );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.address_2;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>City</label>
                                                <input
                                                type="text"
                                                name="city"
                                                value={form.city}
                                                className="form-control placeholder-dark"
                                                placeholder="City"
                                                onChange={ ( e ) =>
                                                {
                                                    resetError( e );
                                                    handleForm( e );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.city;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>Location (State)</label>
                                                <CustomDropdown
                                                type={ DROP_DOWN_TYPES.STATE.toString() }
                                                name={ "state" }
                                                allowEdit={ false }
                                                value={ form?.state ? form.state : "" }
                                                placeholder={ "Choose a state..." }
                                                onChange={ ( name, value ) =>
                                                {
                                                    resetDropdownError( "state" );
                                                    handleDropdownForm(
                                                    name,
                                                    value
                                                    );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.state;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                            </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>Zip Code</label>
                                                <input
                                                type="text"
                                                name="zip_code"
                                                className="form-control placeholder-dark"
                                                placeholder="Zip Code"
                                                value={ form?.zip_code ? form.zip_code : "" }
                                                onChange={ ( e ) =>
                                                {
                                                    resetError( e );
                                                    handleForm( e );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.zip_code;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4">
                                            <div className="form-group">
                                                <label>Commission (%)</label>
                                                <input
                                                type="text"
                                                name="commission"
                                                value={ form?.commission ? form.commission : "" }
                                                className="form-control placeholder-dark"
                                                placeholder="Commission"
                                                onChange={ ( e ) =>
                                                {
                                                    resetError( e );
                                                    handleForm( e );
                                                } }
                                                />
                                                <span style={ { color: "red", fontSize: '10px' } }>
                                                { errors && Array.isArray( errors )
                                                    ? errors.map( ( error: any ) =>
                                                    {
                                                    return error.commission;
                                                    } )
                                                    : "" }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-3 col-md-4" style={{float: "right"}}>
                                            <AiOutlinePlusCircle color='red' style={{cursor: "pointer"}} onClick={() => handleAddContact()}/> 
                                        </div>
                                    </div>
                                    {
                                            contacts.length && contacts.map((contact: any, index: number) => <>
                                                <div className='row'>
                                                    <div className="col-lg-3 col-md-3">
                                                        <div className="form-group">
                                                            <label> {index === 0 ? "Primary Contact First Name" : "Contact First Name"}</label>
                                                            <input
                                                            type="text"
                                                            name="firstName"
                                                            value={ contact.firstName }
                                                            className="form-control placeholder-dark"
                                                            placeholder="Contact Name"
                                                            onChange={ ( e ) =>
                                                            {
                                                                resetError( e );
                                                                handleContactForm( e, index );
                                                            } }
                                                            />
                                                            <span style={ { color: "red", fontSize: '10px' } }>
                                                            { errors && Array.isArray( errors )
                                                                ? errors.map( ( error: any ) =>
                                                                {
                                                                return error.contact_name;
                                                                } )
                                                                : "" }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3">
                                                        <div className="form-group">
                                                            <label>{index === 0 ? "Primary Contact Last Name" : "Contact Last Name"}</label>
                                                            <input
                                                            type="text"
                                                            name="lastName"
                                                            value={ contact.lastName }
                                                            className="form-control placeholder-dark"
                                                            placeholder="Contact Last Name"
                                                            onChange={ ( e ) =>
                                                            {
                                                                resetError( e );
                                                                handleContactForm( e, index );
                                                            } }
                                                            />
                                                            <span style={ { color: "red", fontSize: '10px' } }>
                                                            { errors && Array.isArray( errors )
                                                                ? errors.map( ( error: any ) =>
                                                                {
                                                                return error.lastName;
                                                                } )
                                                                : "" }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3">
                                                        <div className="form-group">
                                                            <label>{index === 0 ? "Primary Contact Email" : "Contact Email"}</label>
                                                            <input 
                                                            type="text"
                                                            name="email"
                                                            value={ contact.email }
                                                            className="form-control placeholder-dark"
                                                            placeholder="Contact Email"
                                                            onChange={ ( e ) =>
                                                            {
                                                                resetError( e );
                                                                handleContactForm( e, index );
                                                            } }
                                                            />
                                                            <span style={ { color: "red", fontSize: '10px' } }>
                                                            { errors && Array.isArray( errors )
                                                                ? errors.map( ( error: any ) =>
                                                                {
                                                                return error.contact_email;
                                                                } )
                                                                : "" }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-4">
                                                        <div className="form-group">
                                                        <label>{index === 0 ? "Primary Contact Phone Number" : "Contact Phone Number"}</label>
                                                        <InputMask
                                                            mask="(999) 999-9999"
                                                            name="phone_number"
                                                            value={ contact.phone_number }
                                                            onChange={ ( e ) =>
                                                                {
                                                                    resetError( e );
                                                                    handleContactForm( e, index );
                                                                } }
                                                            className="form-control"
                                                            placeholder="45891245689"
                                                        />
                                                        <span style={ { color: "red", fontSize: '10px' } }>
                                                            { errors && Array.isArray( errors )
                                                                ? errors.map( ( error: any ) =>
                                                                {
                                                                return error.contact_phone_number;
                                                                } )
                                                                : "" }
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3">
                                                        <div className="form-group">
                                                            <label>{index === 0 ? "Primary Contact Extension" : "Extension"}</label>
                                                            <input
                                                            type="text"
                                                            name="extension"
                                                            value={ contact.extension }
                                                            className="form-control placeholder-dark"
                                                            placeholder="Extension"
                                                            onChange={ ( e ) =>
                                                            {
                                                                resetError( e );
                                                                handleContactForm( e, index );
                                                            } }
                                                            />
                                                            <span style={ { color: "red", fontSize: '10px' } }>
                                                            { errors && Array.isArray( errors )
                                                                ? errors.map( ( error: any ) =>
                                                                {
                                                                return error.contact_extension;
                                                                } )
                                                                : "" }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3">
                                                        <div className="form-group">
                                                            <label>{index === 0 ? "Primary Contact Fax" : "Contact Fax"}</label>
                                                            <input
                                                            type="text"
                                                            name="fax"
                                                            value={ contact.fax }
                                                            className="form-control placeholder-dark"
                                                            placeholder="Contact Fax"
                                                            onChange={ ( e ) =>
                                                            {
                                                                resetError( e );
                                                                handleContactForm( e, index );
                                                            } }
                                                            />
                                                            <span style={ { color: "red", fontSize: '10px' } }>
                                                            { errors && Array.isArray( errors )
                                                                ? errors.map( ( error: any ) =>
                                                                {
                                                                return error.contact_fax;
                                                                } )
                                                                : "" }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {
                                                        index > 0 && 
                                                        <div className="col-lg-1 col-md-1">
                                                            <AiOutlineDelete color="red" style={{marginTop: "50%", cursor: "pointer"}} onClick={() => handleDelete(index)} />
                                                        </div>
                                                    }
                                                </div>
                                            </>)
                                        }
                                    <div className="row">
                                        <div className="col-md-12">
                                        <div className="form-action text-right mt-2 mb-2">
                                            <button
                                            className="btn btn-orange"
                                            type="submit"
                                            >
                                            Save
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                      </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </Layout>

    )
}

export default EditCompany