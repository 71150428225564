import React, { useEffect, useState } from 'react'
import Layout from '../../../shared/components/layout/layout'
import CompaniesListHeader from './header'
import { useDispatch, useSelector } from 'react-redux';
import { searchCompanies } from '../../../store/companies/action';
import Pagination from '../../../shared/components/pagination/pagination';
import { StoreInterface } from '../../../store';
import CompaniesList from './list';
import CompaniesFilter from './filters';
import axios from '../../../Api';
import { exportData } from '../../../utils';
import moment from 'moment';
import { CompaniesExportHeader } from '../../../shared/models';
import { Loader } from '../../../store/loader/model';

const initialFilter = {
    name: null,
    number: null,
    sub_domain: null,
    phone_number: null,
    email: null
}
export interface FilterInterface {
    name: string | null;
    number: string | null;
    sub_domain: string | null;
    phone_number: string | null;
    email: string | null
}
const Companies = () => {
    const dispatch = useDispatch()
    const [totalPageSize, setTotalPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [filters, setFilters] = useState<FilterInterface>(initialFilter);
    const [filtersObj, setFiltersObj] = useState<any>(null);
    const [sortType, setSortType] = useState('DESC');
    const [sortBy, setSortBy] = useState('updatedAt');
    const [expandFilters, setExpandFilters] = useState<boolean>(false);
    const pageInfo = useSelector((state: StoreInterface) => state.companiesPage);
    const totalCount = pageInfo?.count ? pageInfo.count : 0;
    const handleFilters = (e: any) => {
        const name = e.target.name
        setFilters({
            ...filters,
            [name]: e.target.value,
        });
    };
    const createSearchObject = () => {
        const searchObj: any = {};
        if (filters.name) {
            searchObj.name = filters.name;
        }
        if (filters.number) {
            searchObj.number = filters.number
        }
        if (filters.sub_domain) {
            searchObj.sub_domain = filters.sub_domain
        }
        if (filters.phone_number) {
            searchObj.phone_number = filters.phone_number;
        }
        if (filters.email) {
            searchObj.email = filters.email;
        }
        setFiltersObj(searchObj)
        return { ...searchObj, sortBy, sortType};
    };

    useEffect(() => {
        dispatch(searchCompanies(createSearchObject(), totalPageSize, pageNumber));
      // eslint-disable-next-line
    }, [sortBy, sortType]);
    useEffect(() => {
        dispatch(searchCompanies(createSearchObject(), totalPageSize, pageNumber));
        // eslint-disable-next-line
    }, [])

    const searchHandler = () => {
        setPageNumber( 0 );
        dispatch(searchCompanies(createSearchObject(), totalPageSize, 0));
    };

    const changePageSize = (pageSizeToUpdate: number) => {
        setPageNumber( 0 );
        setTotalPageSize( pageSizeToUpdate );
        dispatch(searchCompanies(createSearchObject(), pageSizeToUpdate, 0));
    };

    const changePageNumber = (page_no: number) => {
        setPageNumber( page_no );
        dispatch(searchCompanies(createSearchObject(), totalPageSize, page_no));
    };

    const reset = () => {
        setPageNumber( 0 );
        setTotalPageSize( 10 );
        setFilters(initialFilter);
        dispatch(searchCompanies({}, 10, 0));
    };

    const handleExport = async () => {
        try {
            dispatch(new Loader(true).action());
            const response = await axios.post('/api/v1/export/companies', {
              filters: filtersObj
            })
            exportData(
              response.data.data,
              "Companies-" + moment().format("MMMM-Do-YYYY-HH-mm") + ".xlsx",
              CompaniesExportHeader,
              'companies'
            ); 
            dispatch(new Loader(false).action());
          } catch(err) {
      
          }
    };
    return (
        <>
    <Layout>
          <section className="content-body">
            <header className="page-header">
              <div className="page_title">
                <h2>Companies</h2>
              </div>
            </header>

            <div className="page_content">
              <div className="white-box">
                <CompaniesFilter 
                    filters={filters}
                    setExpandFilters={(status) => setExpandFilters(status)}
                    handleFilters={handleFilters}
                    reset={reset}
                    search={searchHandler}
                    expandFilters={expandFilters}
                />
                <section className="card mb20">
                  <div className="card-body pb-0">
                    <CompaniesListHeader
                      totalPageSize={totalPageSize}
                      handleExport={handleExport}
                      changePageSize={(value) => changePageSize(value)}
                    />
                    <CompaniesList setSortBy={setSortBy} sortBy={sortBy} sortType={sortType} setSortType={setSortType} pageInfo={pageInfo} />
                  </div>
                </section>
                <div className="table-paging">
                  <Pagination
                    totalItems={totalCount}
                    totalPageSize={totalPageSize}
                    pageNumber={pageNumber}
                    changePageNumber={(page) => changePageNumber(page)}
                  />
                </div>
              </div>
            </div>
          </section>
          </Layout>
      </>
    )
}

export default Companies