import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Api from "../../Api";

const CompanyFinance = ({
  companyId
}: {companyId: number}) => {
  const [financeData, setFinanceData] = useState<{
    "totalAmount": number
    "companyData": string,
    "vehicleCount": number
  } | null>(null)
  useLayoutEffect(() => {
    const fetchCompanyFinance = async () => {
        try {
            const res = await Api.get(`/api/v1/companies/${companyId}/finances`)
            if(res?.data?.data) {
                setFinanceData(res?.data?.data)
            }
        }catch(e: any){
            throw e.mesage
        }
    }
    fetchCompanyFinance()
  }, [companyId])  
  return (
    <section className="card mb20">
      <header className="card-header card-head-icon">
        <h2 className="card-title">Company Stats</h2>
      </header>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6 col-xl-12">
            <ul className="info-list">
              <li>
                <div className="info-list-item">
                  <label className="info-label">Company Since</label>
                  <div className="info-name">  
                      {financeData?.companyData}
                  </div>
                </div>
              </li>

              <li>
                <div className="info-list-item">
                  <label className="info-label">Number of vehicle</label>
                  <div className="info-name">  
                      {financeData?.vehicleCount}
                  </div>
                </div>
              </li>
              <li>
                <div className="info-list-item">
                  <label className="info-label">Total Revenue</label>
                  <div className="info-name">  
                      {financeData?.totalAmount}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyFinance;
